.WelcomeContainer {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
color: #d6322c;
background: #fbfcf0;

}

.WelcomeContent {
display: flex;
justify-content: center;
align-items: center;
padding: 0.5rem;

}

@media only screen and (max-width:1000px) {
    .WelcomeContent {
    flex-direction: column;
    }
}

@media screen and (max-width: 820px) {
    .WelcomeContext, .WelcomeContentText {
        font-size: 1.2rem;
        text-align: center;
    }
    
  }

.WelcomeImg {
height: 100%;
width: 100%;
display: flex;
justify-content: center;
padding: 2rem;

}

.Img {
background-size: cover;
background-position: center;
background-repeat: no-repeat;
object-fit: cover;
border-radius: 25px;
height: 300px;
}

@media only screen and (min-width:300px) {
    .Img {
    width: 300px;
    }
}
@media only screen and (min-width:400px) {
    .Img {
    width: 400px;
    }
}
@media only screen and (min-width:600px) {
    .Img {
    width: 500px;
    }
}
@media only screen and (min-width:800px) {
    .Img {
    width: 800px;
    }
}
@media only screen and (min-width:1000px) {
    .Img {
    width: 500px;
    height: 400px;
    }
}
@media only screen and (min-width:1200px) {
    .Img {
    width: 600px;
    }
}
@media only screen and (min-width:1500px) {
    .Img {
    width: 750px;
    }
}
@media only screen and (min-width:1800px) {
    .Img {
    width: 900px;
    }
}


.WelcomeContentText {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 2rem;
}

@media only screen and (min-width:1200px) {
    .WelcomeContentText {
    padding: 3rem 6rem;
    }
}
@media only screen and (min-width:1500px) {
    .WelcomeContentText {
    padding: 3rem 9rem;
    }
}

.WelcomeContentTitle h2 {
font-size: 3.6rem;
padding-bottom: 2rem;
font-weight: bold;

}

.WelcomeText {
font-size: 1.8rem;
}

&:not(:last-child) {
    margin-bottom: 2rem;
}
