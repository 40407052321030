.RecipeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #d6322c;
    background: #fbfcf0;
    }

    .RecipeContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    }

    @media only screen and (max-width:1000px) {
        .RecipeContent {
        flex-direction: column;
        }
    }

    .RecipeContentText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;

    }

    .RecipeShow {
        background-color: blanchedalmond;
        border-color: brown;
        border-style: solid;
        border-width: 3px;
        width: 120%;
        height: 100%;
        padding: 1rem;
        margin: 0.8rem;
        border-radius: 10px;
        line-height: 1.5;
    }

    @media only screen and (min-width:1200px) {
        .RecipeContentText {
        padding: 3rem 6rem;
        }
    }
    @media only screen and (min-width:1500px) {
        .RecipeContentText {
        padding: 3rem 9rem;
        }
    }

    .RecipeContentTitle {
    font-size: 3.5rem;
    font-weight: bold;
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
