.navbar {
  background: #ebd9b0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}
.logo_image {
    height: 35%;
    width: 38%;
    cursor: pointer;
    justify-self: start;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 0.4rem;
}

.nav-item {
  height: 80px;
  font-size: 1.8rem;
}

.nav-links {
  color: #d6322c;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  height: 100%;
  position: relative;
}

.nav-links:after 
{
    content: "";
    position: absolute;
    background-color: #d6322c;
    height: 3px;
    width: 0;
    transition: 0.4s;
}
.nav-links:after 
{
    left: 0;
    bottom: 15px;
}

.nav-links:hover:after
{
width: 100%;
}
.fa-bars {
  color: 'red';
}
  
.nav-links-mobile {
  display: none;
}
  
.menu-icon {
  display: none;
}
/*MOBILE*/
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
    top:0;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: linear-gradient(90deg, #ebd9b0 0%, #ebd9b0 100%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .logo_image {
    height: 92%;
    cursor: pointer;
    justify-self: start;
}

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:before,
  .nav-links:after 
  {
    display: none;
  }
  .nav-links:hover {
    background-color: #fff;
    color: #d6322c;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #d6322c;
    font-size: 2rem;
    }

    .fa-bars {
      color: #d6322c;
    }
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 0.4rem auto;
      border-radius: 20px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #d6322c;
      padding: 14px 20px;
      border: 1px solid #ebd9b0;
      transition: all 0.3s ease-out;
    }
    .nav-links-mobile:hover {
      background: #ebd9b0;
      color: #ebd9b0;
      transition: 250ms;
    }
}